import React from "react";
import '../css/logo.css';

const Navbar = () => {

    return(
        <>
        <nav className="navbar navbar-expand-lg bg-custom">
  <div className="container-fluid">
    <a className="navbar-brand" href="#intro">ZB</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link text-custom" aria-current="page" href="#about">0001 - About Me</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-custom" href="#journey">0010 - Journey</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-custom" href="#projects">0011 - Projects</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-custom" href="#projects">0100 - Courses and Awards</a>
        </li>
        <li className="nav-item">
          <a className="nav-link disabled" aria-disabled="true">Disabled</a>
        </li>
      </ul>
    </div>
  </div>
  
</nav>
<hr></hr>
</>
    );
};
export default Navbar;