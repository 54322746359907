import React, { useState, useEffect } from "react";
import Navbar from "./components/navbar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/logo.css';
import './css/styles.css';


const Main = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 3500); // Adjust the time as needed
    }, []);

    const hexColors = ['#0e31e9', '#730b9f', '#448a9f', '#b90ee1', '#7fcbda', 
    '#d1b651', '#cf556e', '#ae6e92', '#572744', '#cda7f5', 
    '#d81f68', '#645bbd', '#cd7835', '#3a1c60', '#339283', 
    '#62bf37', '#691cab', '#1827b9', '#a70517', '#e31f6b', 
    '#56d5a0', '#804ed3', '#d07156', '#0103ad', '#66c9ad', 
    '#f6baa3', '#FF3131', '#39FF14'];


    const languageSkills = [
        { name: 'HTML', description: 'Semantic Markup', color: hexColors[0] },
        { name: 'CSS', description: 'Styling & Animations', color: hexColors[1] },
        { name: 'JavaScript', description: 'Dynamic Interactivity', color: hexColors[2] },
        { name: 'jQuery', description: 'DOM Manipulation', color: hexColors[3] },
        { name: 'Java', description: 'Enterprise Applications', color: hexColors[4] },
        { name: 'JavaFX', description: 'Desktop GUI', color: hexColors[5] },
        { name: 'Maven', description: 'Build Automation', color: hexColors[6] },
        { name: 'JUnit', description: 'Unit Testing', color: hexColors[7] },
        { name: 'Mockito', description: 'Mocking Framework', color: hexColors[8] },
        { name: 'Spring Boot', description: 'Microservices', color: hexColors[9] },
        { name: 'Thymeleaf', description: 'Server-Side Java Template Engine', color: hexColors[10] },
        { name: 'C++', description: 'Systems Programming', color: hexColors[11] },
        { name: 'C#', description: 'Object-Oriented Language', color: hexColors[12] },
        { name: '.NET', description: 'Framework for Windows', color: hexColors[13] },
        { name: 'SQL', description: 'Database Querying', color: hexColors[14] },
        { name: 'Relational Databases', description: 'Structured Data Storage', color: hexColors[15] },
        { name: 'Python', description: 'Data Science & Scripting', color: hexColors[16] },
        { name: 'React', description: 'UI Component Library', color: hexColors[17] },
        { name: 'MongoDB', description: 'NoSQL Database', color: hexColors[18] },
        { name: 'Express.js', description: 'Web Application Framework', color: hexColors[19] },
        { name: 'Node.js', description: 'Server-Side JavaScript', color: hexColors[20] },
        { name: 'Passport.js', description: 'Authentication Middleware', color: hexColors[21] },
        { name: 'bcrypt', description: 'Password Hashing', color: hexColors[22] },
        { name: 'CORS', description: 'Cross-Origin Resource Sharing', color: hexColors[23] },
        { name: 'Axios', description: 'Promise-Based HTTP Client', color: hexColors[24] },
        { name: 'Bootstrap', description: 'CSS Framework', color: hexColors[25] },
        { name: 'AWS', description: 'Cloud Computing Hosting ', color: hexColors[26]},
        { name: "Linux", description: 'Open Source Opertating System', color: hexColors[27]}
        ];

    

        const LanguageBadge = ({ name, description, color }) => {
            return (
                <div className="language-badge" style={{ '--hover-color': color }}>
                    <div className="badge-name">{name}</div>
                    <div className="badge-description">{description}</div>
                </div>
            );
        };
        

        document.addEventListener("DOMContentLoaded", function() {
            const elements = document.querySelectorAll('.about, .journey, .project');
        
            function isElementInViewport(el) {
                const rect = el.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            function checkVisibility() {
                elements.forEach(element => {
                    const elementTop = element.getBoundingClientRect().top;
                    const isVisible = elementTop < window.innerHeight && elementTop > 0;
            
                    if (isVisible) {
                        element.classList.add('visible');
                    }
                });
            }
            
        
            window.addEventListener('scroll', checkVisibility);
            checkVisibility(); // Initial check on page load
        });
    
return(
<>
{loading && (
                <div className="loader-wrapper">
                    <div className="diamond">
                        <span className="zb-text">ZB</span>
                    </div>
                </div>
            )}
<Navbar />
<div className="content-wrapper">
<div className="intro" id="intro">
    <div className="intro-1">
    <p>Hello, my name is</p>
    </div>
    <div className="intro-name">
    <h1>Zac Bondy</h1>
    </div>
    <div className="intro-2">
    <p>I am a <div className="Special1">computer programmer</div> currently finishing a diploma in Full-Stack Web Design and Database Management. My passion spans across various programming fields, including mobile app development, database architecture, and general software solutions, showcasing a fascination for versatile and innovative technological approaches.
    </p>
    </div>
</div>

<div className="about hidden" id="about">
    <h1>0001 - About Me</h1>
    <hr></hr>
    <div className="about-container">
        <div className="about-pic">
            <img src="images/me.jpg" alt="Zac Bondy" width="200" height="200"/>
        </div>
        <div className="about-content">
            <p>I am a Canadian Computer Programmer specializing in web development. </p> <p>With 7 years in software development, I bring a unique blend of innovation and expertise. My portfolio reflects my passions for gaming, sports statistics, and comics, infusing creativity into my technical work.</p> <p>Explore my projects and see how a Canadian programmer combines diverse interests into a distinctive programming style.</p>
        </div>
    </div>
    <div className="badges-container">
        <div className="badges-p">
   <p>My programming journey, spanning seven years, has afforded me the opportunity to explore and master a diverse range of languages and technologies. Below is a selection of these skills that I have acquired:</p>
   </div>
   <div className="language-badges">
                    {languageSkills.map((skill, index) => (
                        <LanguageBadge
                            key={skill.name}
                            name={skill.name}
                            description={skill.description}
                            color={hexColors[index % hexColors.length]}
                        />
                    ))}
                </div>
            </div>

    

</div>

<div className="journey hidden" id="journey">
    <h1>0010 - My Programming Journey</h1>
    <hr></hr>
    <Tabs>
        <TabList>
            <Tab>#0 Introduction</Tab>
            <Tab>#1 The Next Step</Tab>
            <Tab>#2 My Current Endeavours</Tab>
            <Tab>#2.5 Industry Experience</Tab>
        </TabList>
        <hr></hr>
        <TabPanel>
        <h3>#0 - High School</h3>
        <div className="image-text-wrapper">
        <div className="journey-pic-highschool">
        <img src="images/north-mid.png" alt="Zac Bondy" class="float-image"/>
                    </div>
            <p>My programming journey began in my junior year of high school with an introductory course in Visual Basic.NET. Despite its focus on basic GUIs, I found myself captivated, eager to explore beyond the fundamentals. This interest, somewhat unexpected for a drama student like myself, grew into a passion that led me to the advanced programming course in my senior year.

            </p>
            <p>There, I transitioned to Java, which introduced a new level of complexity and a focus on algorithmic problem-solving, far beyond the drag-and-drop interfaces of Visual Basic.NET. This deeper dive into programming not only enhanced my skills but also solidified my decision to pursue a career in this dynamic field post-secondary. It was in these formative years that my academic interest transformed into a professional ambition.</p>
            </div>
        </TabPanel>
        <TabPanel>
        <h3>#1 - Fanshawe College</h3>
        <div class="image-text-wrapper">
        <div className="journey-pic-fanshawe">
            <img src="images/fanshawe.jpeg" alt="Zac Bondy" class="float-image" />
       </div>
           
                <p>My programming journey further evolved at Fanshawe College, where I pursued studies in Computer Programming and Analysis. During my tenure, which spanned two of the three-year program, I gained an extensive array of knowledge in various programming disciplines, despite withdrawing later due to personal and financial reasons.</p>
                <p>The curriculum at Fanshawe College enriched my understanding of Object-Oriented Programming, delving deep into languages such as Java, C++, and C#. It was here that I was first introduced to relational databases and SQL, as well as the intricacies of mobile development and web design. The program also covered essential programming concepts, including Data Structures and Algorithms, Object-Oriented Analysis & Modeling, and web scripting techniques.</p>
                <p>Reflecting on my time at Fanshawe College, I am immensely grateful for the valuable experiences and knowledge I acquired, which have been fundamental in shaping my skills and approach in the field of computer programming.</p>
              </div>
        </TabPanel>
        <TabPanel>
        <h3>#2 - Lambton College</h3>
        <div class="image-text-wrapper">
        <div className="journey-pic-lambton">
            <img src="images/lambton.jpg" alt="Zac Bondy" class="float-image"/>
 </div>
            <p>My enrollment at Lambton College marked a pivotal and rejuvenating phase in my career, as it steered me back to my core passion: programming. The program at Lambton was primarily oriented towards web design, offering me an immersive educational experience in this domain. Here, I expanded my programming repertoire, learning Python and reinforcing my knowledge of Object-Oriented Programming with languages like Java and C#.</p>
            <p>A significant part of my education at Lambton involved gaining a comprehensive understanding of the various facets of web development, including front-end, back-end, and full-stack development. I was introduced to the MERN stack and familiarized myself with front-end technologies such as Axios, jQuery, and Bootstrap. The program also built upon my foundational knowledge of SQL from Fanshawe College, advancing my skills in designing, creating, populating, and deploying relational databases. Moreover, I delved into NoSQL databases, notably MongoDB, broadening my database management capabilities.</p>
            <p>Crucially, Lambton College emphasized practical, hands-on projects that aligned closely with industry standards. These projects, which I have detailed in the 'Projects' section below, are reflective of the real-world applications of the skills and knowledge acquired during my time at the college. This pragmatic approach to education has been instrumental in preparing me for the demands and challenges of the professional world.</p>
        </div>
        </TabPanel>
        <TabPanel>
        <h3>#2.5 - Kognitive Sales Solutions</h3>
        <div class="image-text-wrapper">
            <div className="journey-pic-kognitive">
            <img src="images/kognitive.png" alt="Zac Bondy" class="float-image"/>
            </div>
            <p>My inaugural professional engagement was with Kognitive Sales Solutions, serving in the capacity of a Field Support Agent. I was employeed here for 2 years, with 1 year being when I was enrolled at Lambton College. In this role, I was responsible for providing technical assistance to the sales team and aiding the Human Resources department with various reporting tasks. My proficiency in programming proved invaluable, particularly when navigating the company's <div className="special">database</div> to facilitate troubleshooting and the generation of detailed reports.</p>
            <p>During my tenure, I gained substantial insight into the intricacies of <div className="special">database design</div> and honed my skills in crafting complex <div className="special">SQL</div> queries to extract pertinent information for reporting purposes. Additionally, I played a pivotal role in troubleshooting issues faced by sales representatives accessing the system remotely via a web application.</p>
            <p>A significant part of my learning curve involved mastering the integration of databases with <div className="special">Excel</div>. I developed the ability to set up automated reports that could be refreshed directly within <div className="special">Excel</div>, thereby streamlining the data retrieval process from the database. This knowledge enabled me to not only understand the mechanics of these reports but also to innovate and create new ones that better served the operations team.</p>
            <h4>Skills in Action</h4>
            <div class="image-text-wrapper">
            <div className="journey-pic-skills">
            <img src="images/skills.webp" alt="Zac Bondy" class="float-image"/>
            </div>
            <p>One of my most notable achievements was the development of a comprehensive report for the Human Resources department. This task entailed extracting hundreds of thousands of rows from the database and filtering them to meet specific HR requirements. Traditionally, the company would allocate <div className="special">4-5 days</div> to compile this report. However, leveraging the <div className="special">Python skills</div> I acquired at Lambton College, I crafted scripts that significantly expedited this process by eliminating time-consuming and complex calculations that were beyond the scope of <div className="special">SQL queries</div>. Furthermore, using the <div className="special">advanced Excel techniques</div> learned at Lambton College, I formatted the data in the report to enhance its clarity and readability for the HR team. Remarkably, this entire process, from initiation to delivery, was completed in a mere <div className="special">6 hours</div>.</p>
            </div>
            </div>
        </TabPanel>
    </Tabs>
    </div>
   <div className="project hidden" id="projects">
    <h1>0011 - Projects</h1>
    <hr></hr>
    <div class="grid-container">
    
    <div class="grid-item">
    <h4>Project-MERN</h4>
        <img src="images/loginPage.png" alt="Description1"/>
        <div class="text-box">An interactive web application that simulates a corporate employee database, enabling users to view, create, modify, and securely delete employee records. It features robust user authentication and advanced password encryption for secure access. Try the demo below! Please note its not mobile friendly yet.</div>
        <div class="link-area">
            <a href="https://github.com/TheGHH85/MERN-Project">
            <img src="images/git-icon.jpg" alt="Github icon"/>
            </a>
            <a href="http://54.204.65.92:3000/">
            
            <img src="images/web-icon.webp" alt="website icon"/>
          
            </a>
        </div>
        <div class="bubble-list">
            <span class="bubble">React</span>
            <span class="bubble">Node.js</span>
            <span class="bubble">Express.js</span>
            <span class="bubble">MongoDB</span>
            <span class="bubble">Passport.js</span>
            <span class="bubble">bcrypt</span>
            <span class="bubble">Bootstrap</span>
            <span class="bubble">Axios</span>
            <span class="bubble">CORS</span>
        </div>
    </div>

 
    <div class="grid-item">
    <h4>Project-Weather-App</h4>
        <img src="images/weather-app.png" alt="Description2"/>
        <div class="text-box">A web application designed to provide real-time meteorological data, including temperature, wind speed, and humidity, for any specified city. Leveraging the OpenWeather API, this platform delivers accurate and up-to-date weather information at your fingertips, enhancing your ability to plan and adapt to varying weather conditions efficiently.</div>
        <div class="link-area">
        <a href="https://github.com/TheGHH85/weather-app">
            <img src="images/git-icon.jpg" alt="Github icon"/>
            </a>
            <a href="https://theghh85.github.io/weather-app/">
            
            <img src="images/web-icon.webp" alt="website icon"/>
          </a>
        </div>
        <div class="bubble-list">
            <span class="bubble">HTML</span>
            <span class="bubble">CSS</span>
            <span class="bubble">JavaScript</span>
            <span class="bubble">JSON</span>
          
        </div>
    </div>

    
    <div class="grid-item">
    <h4>Project-Bondy-Brewers</h4>
        <img src="images/bondy-brewers.png" alt="Description3"/>
        <div class="text-box">A professional mock corporate website developed using vanilla HTML/CSS, showcasing fundamental web design skills acquired in my first year of study.</div>
        <div class="link-area">
        <a href="https://github.com/TheGHH85/bondy-brewers">
            <img src="images/git-icon.jpg" alt="Github icon"/>
            </a>
         
        </div>
        <div class="bubble-list">
            <span class="bubble">HTML</span>
            <span class="bubble">CSS</span>
            <span class="bubble">jQuery</span>
          </div>
    </div>
    <div class="grid-item">
    <h4>Project-Scheduler</h4>
    <h5>In Development....</h5>
    <img src="images/project-scheduler.png" alt="Description4"/>
    <div class="text-box">I am presently involved in an exciting partnership with a business, working together to create a custom-tailored scheduling system and database meticulously designed to meet their unique operational requirements. I invite you to explore the demo by clicking the link below, and I encourage you to review the README in my GitHub repository to discover the full array of features this project has to offer.</div>
    <div class="link-area">
            <a href="https://github.com/TheGHH85/Project-Scheduler">
            <img src="images/git-icon.jpg" alt="Github icon"/>
            </a>
            <a href="http://52.91.94.163:3000/">
            
            <img src="images/web-icon.webp" alt="website icon"/>
          
            </a>
        </div>
        <div class="bubble-list">
            <span class="bubble">React</span>
            <span class="bubble">Node.js</span>
            <span class="bubble">Express.js</span>
            <span class="bubble">MongoDB</span>
            <span class="bubble">Passport.js</span>
            <span class="bubble">bcrypt</span>
            <span class="bubble">Bootstrap</span>
            <span class="bubble">Axios</span>
            <span class="bubble">CORS</span>
            <span class="bubble">AWS</span>
            <span class="bubble">Tailwindcss</span>
        </div>
</div>
<div class="grid-item">
    <h4>Project-Dashboard</h4>
    <h5>In Development....</h5>
    <img src="images/dashboard.png" alt="Description4"/>
    <div class="text-box">This web application, designed as a user dashboard featuring news, weather, and stock updates, was developed for a cloud computing course. It focuses on practical API usage and data presentation, leveraging React.js, Express.js, and Node.js. Hosted on an AWS EC2 instance, the project exemplifies the application of cloud computing in web development. The project will be listed here to view once completed.</div>
    <div class="bubble-list">
            <span class="bubble">React</span>
            <span class="bubble">Tailwindcss</span>
            <span class="bubble">Express.js</span>
            <span class="bubble">Node.js</span>
          </div>
</div>
<div class="grid-item">
    <h4>Project-Tensorflow</h4>
    <img src="images/tensorflow.png" alt="Description4"/>
    <div class="text-box">This project is focused on exploring machine learning, specifically in creating and training a model to classify images into 10 distinct categories. The model is created using Tensorflow libraries. After its training phase, the model will enable users to upload images for categorization based on its learned criteria. The results will be accessible upon the project's completion, showcasing the practical application of machine learning in image classification.</div>
    <div class="link-area">
            <a href="https://github.com/TheGHH85/Project-Tensorflow">
            <img src="images/git-icon.jpg" alt="Github icon"/>
            </a>
          
        </div>
    <div class="bubble-list">
            <span class="bubble">Tensorflow</span>
            <span class="bubble">Python</span>
          </div>
</div>
</div>


   
   </div>

  
            </div>
        </>
    
           

)

};

export default Main;